<template>
    <button
        class="flex py-2 pr-[12px] pl-[16px] self-stretch justify-between items-center rounded bg-preto-100 hover:bg-[#F5F5F5] shadow-button-select-user"
        style="border: 1px solid #1C86E3;"
        @click="qrCodeDialog = true">
        <span class="text-preto-cone font-rubik text-sm font-medium">Entrar com QR code</span>
        <QrCodeIcon />
    </button>
    <QrCodeDialog v-if="qrCodeDialog" v-model="qrCodeDialog" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import QrCodeDialog from './QrCodeDialog.vue';
import QrCodeIcon from './icons/QrCodeIcon.vue';

const qrCodeDialog = ref()
</script>
