<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="1" fill="white"/>
        <g clip-path="url(#clip0_3145_29105)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.64 19.2047C27.64 18.5665 27.5827 17.9529 27.4764 17.3638H19V20.8451H23.8436C23.635 21.9701 23.0009 22.9233 22.0477 23.5615V25.8197H24.9564C26.6582 24.2529 27.64 21.9456 27.64 19.2047Z" fill="#4285F4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9998 28C21.4298 28 23.467 27.1941 24.9561 25.8195L22.0475 23.5613C21.2416 24.1013 20.2107 24.4204 18.9998 24.4204C16.6557 24.4204 14.6716 22.8372 13.9638 20.71H10.957V23.0418C12.4379 25.9831 15.4816 28 18.9998 28Z" fill="#34A853"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9641 20.7098C13.7841 20.1698 13.6818 19.593 13.6818 18.9998C13.6818 18.4066 13.7841 17.8298 13.9641 17.2898V14.958H10.9573C10.3477 16.173 10 17.5476 10 18.9998C10 20.4521 10.3477 21.8266 10.9573 23.0416L13.9641 20.7098Z" fill="#FBBC05"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9998 13.5795C20.3211 13.5795 21.5075 14.0336 22.4402 14.9255L25.0216 12.3441C23.4629 10.8918 21.4257 10 18.9998 10C15.4816 10 12.4379 12.0168 10.957 14.9582L13.9638 17.29C14.6716 15.1627 16.6557 13.5795 18.9998 13.5795Z" fill="#EA4335"/>
        </g>
        <defs>
        <clipPath id="clip0_3145_29105">
        <rect width="18" height="18" fill="white" transform="translate(10 10)"/>
        </clipPath>
        </defs>
    </svg>        
</template>