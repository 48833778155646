import UserService from "@/services/user";
import { type Unsubscribe } from "firebase/firestore";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    userData: null as any,
    userIsLoggedIn: false,
    unsubscribeUserDataListener: null as Unsubscribe | null,
  }),
  actions: {
    setUserData(userData: any) {
      this.userData = userData;
    },
    clearUserData() {
      this.userData = null;
    },
    async setUserIsLoggedIn() {
      this.unsubscribeUserDataListener = await UserService.listenUserData();
      this.userIsLoggedIn = true;
    },
    setUserIsLoggedOff() {
      this.unsubscribeUserDataListener?.();
      this.clearUserData();
      this.userIsLoggedIn = false;
    },
  },
  getters: {
    hasActiveOrganization(): boolean {
      return this.userIsLoggedIn && !!this.userData?.active_organization?.id;
    },
    isFirstAccess(): boolean {
      return this.userIsLoggedIn && this.userData?.is_first_access;
    },
    getUserData(): any {
      return this.userData;
    },
    activeOrganizationId(): string {
      return this.userData?.active_organization?.id;
    },
    isOrganizationSelfRegistration(): boolean {
      return this.userData?.is_organization_self_registration ?? false;
    },
  },
});
