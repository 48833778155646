import Environment from "@/config/env";
import { useSnackbarStore } from "@/store/snackbar";
import { type Ref } from "vue";

export function useProject(selectedUserType?: Ref<string>) {
    const snackBarStore = useSnackbarStore()

    const isTeacher = () => selectedUserType?.value === 'teacher'

    const isStudent = () => selectedUserType?.value === 'student'

    const redirectToStudentProject = () => location.href = `${Environment.STUDENT_URL}/auth/check-status`

    const redirectToTeacherProject = () => location.href = `${Environment.TEACHER_URL}/auth/check-status`

    const hasSelectedUserType = () => {
        if (!selectedUserType?.value) {
            snackBarStore.showSnackbar(['Selecione um ambiente para fazer login'], 'error')
        }
        return !!selectedUserType?.value
    }

    const redirectToProject = () => {
        if (isTeacher()) {
            redirectToTeacherProject()
            return
        }

        if (isStudent()) {
            redirectToStudentProject()
            return
        }
    }

    return {
        isTeacher,
        isStudent,
        redirectToProject,
        hasSelectedUserType,
        redirectToStudentProject
    }
}