export default class Environment {
    static get FIREBASE_CONFIG () {
        return JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG)
    }

    static get STORE_IOS_URL () {
        return import.meta.env.VITE_STORE_IOS_URL as string
    }

    static get STORE_ANDROID_URL () {
        return import.meta.env.VITE_STORE_ANDROID_URL as string
    }

    static get LOGO_URL () {
        return import.meta.env.VITE_LOGO_URL as string
    }

    static get STUDENT_URL () {
        return import.meta.env.VITE_STUDENT_URL as string
    }

    static get TEACHER_URL () {
        return import.meta.env.VITE_TEACHER_URL as string
    }

    static get PROJECT_NAME () {
        return import.meta.env.VITE_PROJECT_NAME as string ?? 'Pruvo'
    }

    static get EMAIL_REGEX () {
        return /.+@.+\..+/
    }

    static get SENTRY_DSN () {
        return String(import.meta.env.VITE_SENTRY_DSN)
    }

    static get IMAGE_URL () {
        return import.meta.env.VITE_IMAGE_URL
    }
}
