import { onMounted, ref } from "vue";
import BrowserEnum from "@/enum/browser.enum";
import OperationSystemEnum from "@/enum/operation-system.enum";

export function useNavigator() {
    const operationSystem = ref<OperationSystemEnum>(OperationSystemEnum.Unknown);
    const browser = ref<BrowserEnum>(BrowserEnum.Unknown);

    onMounted(() => {
        getOperationSystem();
        getBrowser();
    })

    function getOperationSystem() {
        const userAgent = window.navigator.userAgent;
        
        if (userAgent.match(/Windows/i)) {
            operationSystem.value = OperationSystemEnum.Windows;
        }
        if (userAgent.match(/Mac/i)) {
            operationSystem.value = OperationSystemEnum.MacOS;
        }
        if (userAgent.match(/Linux/i)) {
            operationSystem.value = OperationSystemEnum.Linux;
        }
        if (userAgent.match(/Android/i)) {
            operationSystem.value = OperationSystemEnum.Android;
        }
        if (userAgent.match(/iPhone|iPad|iPod/i)) {
            operationSystem.value = OperationSystemEnum.iOS;
        }
        if (userAgent.match(/CrOS/i)) {
            operationSystem.value = OperationSystemEnum.ChromeOS;
        } 
    }

    function getBrowser() {
        const userAgent = window.navigator.userAgent;

        if (userAgent.match(/Chrome/i)) {
            browser.value = BrowserEnum.Chrome;
        }
        if (userAgent.match(/Firefox/i)) {
            browser.value = BrowserEnum.Firefox;
        }
        if (userAgent.match(/Safari/i)) {
            browser.value = BrowserEnum.Safari;
        }
        if (userAgent.match(/Edg/i)) {
            browser.value = BrowserEnum.Edge;
        }
        if (userAgent.match(/MSIE/i) || userAgent.match(/Trident/i)) {
            browser.value = BrowserEnum.IE;
        }
        if (userAgent.match(/Opera/i)) {
            browser.value = BrowserEnum.Opera;
        }
    }

    // OS
    const isChromeOs = () => operationSystem.value === OperationSystemEnum.ChromeOS;
    const isMacOs = () => operationSystem.value === OperationSystemEnum.MacOS;
    const isWindows = () => operationSystem.value === OperationSystemEnum.Windows;
    const isLinux = () => operationSystem.value === OperationSystemEnum.Linux;
    const isAndroid = () => operationSystem.value === OperationSystemEnum.Android;
    const isIos = () => operationSystem.value === OperationSystemEnum.iOS;
    const isUnknownOs = () => operationSystem.value === OperationSystemEnum.Unknown;

    // Navigator
    const isChrome = () => browser.value === BrowserEnum.Chrome;
    const isFirefox = () => browser.value === BrowserEnum.Firefox;
    const isSafari = () => browser.value === BrowserEnum.Safari;
    const isEdge = () => browser.value === BrowserEnum.Edge;
    const isIE = () => browser.value === BrowserEnum.IE;
    const isOpera = () => browser.value === BrowserEnum.Opera;
    const isUnknownBrowser = () => browser.value === BrowserEnum.Unknown;

    return {
        isChromeOs,
        isMacOs,
        isWindows,
        isLinux,
        isAndroid,
        isIos,
        isUnknownOs,
        isChrome,
        isFirefox,
        isSafari,
        isEdge,
        isIE,
        isOpera,
        isUnknownBrowser
    }
    
}