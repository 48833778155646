<template>
  <v-dialog :modelValue="modelValue" persistent width="420">
    <div class="card">
      <div class="card_top">
        <div class="card_title">
          <QrCodeIcon/>
          <span class="card_title_text">Entrar com QR code</span>
        </div>
        <div class="card_close_icon" @click="closeDialog">
          <CloseIcon/>
        </div>
      </div>
      <div class="card_content">
                <span class="card_content_text">
                    No app {{ projectName }}, clique em <strong
                    style="font-weight: 500;">“Logar aluno com QR code”</strong>,&nbsp;
                    selecione o aluno que deseja acessar o sistema, e aponte a camera para o QR code.
                </span>
        <div class="qrcode">
          <v-progress-circular
              v-if="qrCodeLoading"
              class="qrcode_loading"
              indeterminate
              size="64"
          />
          <vue-qrcode
              v-if="qrCodeValue"
              :class="{
                            'qr_code_loading': qrCodeLoading
                        }"
              :color="{
                            dark: '#000000FF',
                            light: '#FFFFFFFF'
                        }"
              :margin="1"
              :value="qrCodeValue"
              :width="250"
              type="image/jpeg"
          />
        </div>
        <div class="card_bottom">
          <TimerIcon/>
          <span class="card_bottom_text">
                        Atualização em <span style="color: #1C86E3;">{{ moment(timer * 1000).format("mm:ss") }}</span>
                    </span>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import VueQrcode from 'vue-qrcode'
import QrCodeIcon from './icons/QrCodeIcon.vue';
import CloseIcon from './icons/CloseIcon.vue';
import TimerIcon from './icons/TimerIcon.vue';
import {inject, onMounted, onUnmounted, ref} from 'vue';
import moment from 'moment';
import QrCodeService from '@/services/qrcode';
import AuthService from '@/services/auth';
import Environment from '@/config/env';

const authService = new AuthService()
const qrCodeService = new QrCodeService()

defineProps({
  modelValue: Boolean
})

const emit = defineEmits(['update:modelValue'])

const QRCODE_REFRESH_TIME = 300
const projectName = Environment.PROJECT_NAME;
const qrCodeValue = ref()
const qrCodeLoading = ref(false)
const timer = ref(QRCODE_REFRESH_TIME)

const verifyIfUserHasActiveOrganization = inject('verifyIfUserHasActiveOrganization') as Function

onMounted(async () => {
  await generateQrCode()
})

let interval = setInterval(async () => {
  if (timer.value === 1) {
    timer.value = QRCODE_REFRESH_TIME
    await generateQrCode();
    return
  }
  timer.value--
}, 1000)

async function generateQrCode() {
  try {
    qrCodeLoading.value = true
    qrCodeValue.value = await qrCodeService.generateQrCode();
    qrCodeService.subscribeToQrCode(async value => {
      if (value.customToken) {
        qrCodeLoading.value = true;
        try {
          await authService.signInWithCustomToken(value.customToken);
          await verifyIfUserHasActiveOrganization();
        } finally {
          closeDialog();
        }
      }
    })
  } finally {
    qrCodeLoading.value = false
  }
}

function closeDialog() {
  emit('update:modelValue', false)
  clearInterval(interval)
}

onUnmounted(() => {
  clearInterval(interval)
})

</script>

<style scoped>
.card {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}

.card_top {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid var(--preto-90, #E6E6E6);
  background: var(--preto-99, #FCFCFC);
  width: 100%
}

.card_content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 0px 0px 12px 12px;
  background: var(--preto-99, #FCFCFC);
  width: 100%
}

.card_title {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.card_title_text {
  color: var(--Preto-cone, #4A545E);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.card_close_icon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.card_content_text {
  color: var(--preto-20, #333);
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  width: 100%;
}

.card_bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}

.card_bottom_text {
  color: var(--Preto-cone, #4A545E);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.25px;
}

.qrcode {
  position: relative;
}

.qrcode_loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.qr_code_loading {
  opacity: 0.5;
}
</style>