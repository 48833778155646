const ruleRequired = (v: string, message: string) => !!v || message;

const rulesEmail = [
  (v: string) => ruleRequired(v, "O e-mail é obrigatório."),
  (v: string) => /.+@.+\..+/.test(v) || "O e-mail está inválido.",
];

const rulesUsername = [
  (v: string) => ruleRequired(v, "O e-mail ou usuário é obrigatório."),
];

const rulesPassword = [
  (v: string) => ruleRequired(v, "A senha é obrigatória."),
  (v: string) => v.length >= 6 || "A senha precisa ter no mínimo 6 caracteres.",
];

const matchPassword = (password: string) => (v: string) => v === password || "As senhas não coincidem.";

const rulesName = [
  (v: string) => ruleRequired(v, "O nome é obrigatório."),
  (v: string) => v.length >= 3 || "O nome precisa ter no mínimo 3 caracteres.",
];

export { rulesEmail, rulesPassword, rulesName, rulesUsername, matchPassword };
