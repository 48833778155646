import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import { getAuth } from "firebase/auth";
import { app } from "@/config/firebase";
import CreateAccountView from "@/views/CreateAccountView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "login",
      component: LoginView,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPasswordView,
    },
    {
      path: "/create",
      name: "create",
      component: CreateAccountView,
    },
    {
      path: "/organizations",
      name: "organizations",
      component: () => import("@/views/OrganizationsView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/redirect-student",
      name: "redirect-student",
      component: () => import("@/views/RedirectStudentView.vue"),
    },
    {
      path: "/first-access",
      name: "first-access",
      component: () => import("@/views/FirstAccessView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/first-access-teacher",
      name: "first-access-teacher",
      component: () => import("@/views/FirstAccessTeacherView.vue"),
      meta: {
        requiresAuth: true,
      },
    }
  ],
});

router.beforeEach((to) => {
  if (to.meta.requiresAuth) {
    const auth = getAuth(app);
    if (!auth?.currentUser) {
      return {
        name: "login",
      };
    }
  }
});

export default router;
