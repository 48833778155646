<template>
    <AuthLayoutComponent title="Esqueci a senha">
        <v-form
            v-model="validForm"
            @submit="sendEmail"
            v-if="!isEmailSent"
            class="flex flex-col ga-5 w-[356px] xs:w-full">
            <span class="text-preto-cone font-rubik text-base font-normal">Coloque o seu e-mail usado no {{ projectName }}</span>
            <v-text-field
                v-model="email"
                label="E-mail"
                :rules="rulesEmail"
                placeholder="email@email.com.br"
                variant="outlined"
                :persistent-placeholder="true"
                color="grey"
            />
            <RouterLink to="/" class="text-azul-50 text-center font-rubik text-base underline font-normal">
                Voltar para o login
            </RouterLink>
            <PrimaryButton label="Continuar" @click="sendEmail" />
        </v-form>
        <div v-else class="flex flex-col ga-5 w-[356px] xs:w-full">
            <div>
                <span class="text-preto-cone text-center font-rubik text-xl font-normal">Enviaremos as instruções para resetar a sua senha para o e-mail:&nbsp;</span>
                <span class="text-azul-50 text-xl font-normal font-rubik">{{ email }}</span>
            </div>
            <RouterLink to="/" class="text-azul-50 text-center font-rubik text-base underline font-normal">
                Voltar para o login
            </RouterLink>
        </div>
    </AuthLayoutComponent>
</template>

<script setup lang="ts">
import AuthLayoutComponent from '@/components/AuthLayoutComponent.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import { ref } from 'vue';
import AuthService from '@/services/auth';
import Environment from '@/config/env';

const email = ref('')
const isEmailSent = ref(false)
const validForm = ref(false)
const authService = new AuthService()
const projectName = Environment.PROJECT_NAME

const rulesEmail = [
  (v: string) => !!v || 'O e-mail é obrigatório',
  (v: string) => /.+@.+\..+/.test(v) || 'O e-mail está inválido'
]

function sendEmail() {
    if (!validForm.value) return
    try {
        authService.resetPassword(email.value)
        isEmailSent.value = true
    } catch (error) {
        // TODO: handle error
    }
}

</script>
