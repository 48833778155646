<script setup lang="ts">
import Environment from "@/config/env";

const icon_url = Environment.LOGO_URL;
const image_url: string = Environment.IMAGE_URL ?? "/assets/kid.webp";
</script>

<template>
    <div class="relative shadow-default kids-image:hidden">
        <img
            :src="icon_url"
            class="absolute top-[40px] left-[44px] select-none max-w-[120px]"
            alt="Imagem de logo da empresa"
            fetchpriority="low"
            rel="preload">
        <img
            class="rounded-tl-[10px] rounded-bl-[10px] max-w-[882px] max-h-[790px]"
            :src="image_url"
            fetchpriority="low"
            alt="Imagem de crianças estudando"
            rel="preload">
    </div>
</template>
