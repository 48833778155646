<template>
    <div class="flex items-start gap-5 select-none w-full">
        <UserSelectCard
            @selected="selectUserType(userType.id)"
            v-for="userType in userTypes"
            :data-cy="`select-${userType.id}`"
            :key="userType.id"
            :isActive="selectedUserType === userType.id"
            :iconSrc="userType.iconSrc"
            :text="userType.text"
        >
        </UserSelectCard>
    </div>
</template>

<script setup lang="ts">
import UserSelectCard from './UserSelectCard.vue';
import { inject, ref, type Ref } from 'vue';

const userTypes = ref([
    {
        id: 'student',
        iconSrc: '/assets/student.webp',
        text: 'Aluno'
    },
    {
        id: 'teacher',
        iconSrc: '/assets/teacher.webp',
        text: 'Professor'
    }
]);
const selectedUserType = inject('selectedUserType') as Ref<string>;
const hasSelectedEnvironment = ref(false);

const selectUserType = (userType: string) => {
    selectedUserType.value = userType;
    hasSelectedEnvironment.value = true;
}

</script>
