<template>
  <AuthLayoutComponent #default :loading="loading" title="Login">
    <div class="flex flex-col gap-5 w-full">
      <SelectTypeUserComponent class="xs:flex-col xs:justify-center xs:items-center align-center"/>
      <GoogleButton @click="handleGoogleLogin"/>
      <LoginWithQrCodeButton v-if="isStudent()"/>
      <DividerWithTextComponent text="ou"/>
      <v-form
          @submit.prevent
          v-model="validForm"
          class="flex flex-col gap-4"
          @keyup.enter="handleEmailAndPasswordLogin"
      >
        <v-text-field
            v-model="email"
            :error-messages="emailError"
            :rules="rulesUsername"
            color="grey"
            data-cy="email"
            label="E-mail ou usuário"
            type="email"
            variant="outlined"/>
        <div class="flex flex-col gap-1">
          <v-text-field
              v-model="password"
              :append-inner-icon="!isPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isPassword ? 'text' : 'password'"
              data-cy="password"
              label="Senha"
              placeholder="********"
              variant="outlined"
              @click:append-inner="isPassword = !isPassword"
          ></v-text-field>
          <RouterLink
              class="text-azul-50 font-rubik text-xs font-normal underline text-center"
              to="/forgot-password">
            Esqueci a senha
          </RouterLink>
        </div>
        <div class="text-center font-rubik text-sm font-normal">
          Não tem acesso?&nbsp;
          <RouterLink class="text-azul-50 cursor-pointer select-none" to="/create">Cadastre-se</RouterLink>
        </div>
      </v-form>
      <PrimaryButton data-cy="login-button" label="Entrar" @click="handleEmailAndPasswordLogin"/>
    </div>
  </AuthLayoutComponent>
</template>

<script lang="ts" setup>
import AuthLayoutComponent from '@/components/AuthLayoutComponent.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import DividerWithTextComponent from '@/components/DividerWithTextComponent.vue';
import GoogleButton from '@/components/GoogleButton.vue';
import SelectTypeUserComponent from '@/components/SelectTypeUserComponent.vue';
import LoginWithQrCodeButton from '@/components/LoginWithQrCodeButton.vue';
import AuthService from '@/services/auth';
import {onMounted, provide, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useSnackbarStore} from '@/store/snackbar';
import {FirebaseError} from 'firebase/app';
import {rulesPassword, rulesUsername} from '@/utils/rules';
import {useNavigator} from "@/composables/useNavigator"
import {useLoginStore} from '@/store/login';
import {useProject} from '@/composables/useProject';
import {useFlutter} from "@/composables/useFlutter";

const authService = new AuthService();

const email = ref('');
const password = ref('');
const selectedUserType = ref<any>();
const validForm = ref(false);
const emailError = ref<string>('');
const loading = ref(false);
const isPassword = ref<boolean>(false)

const route = useRoute();
const router = useRouter();
const snackBarStore = useSnackbarStore();
const clientNavigator = useNavigator();
const {
  isStudent,
  isTeacher,
  hasSelectedUserType
} = useProject(selectedUserType)
const {isNativePlatform, openLoginWithGoogle: flutterLoginWithGoogle} = useFlutter()

provide('selectedUserType', selectedUserType);
provide('verifyIfUserHasActiveOrganization', routePushOrganizations)

window.addEventListener('flutterLogin', async (e: any) => {
  const detail = (e as CustomEvent).detail
  const {accessToken, idToken, userType} = JSON.parse(detail);
  selectedUserType.value = userType;
  await authService.signInWithCredential(accessToken, idToken)
  await routePushOrganizations()
})

onMounted(async () => {
  await handleLoginWithCustomToken()
})

async function handleGoogleLogin() {
  if (!hasSelectedUserType()) return

  try {
    loading.value = true
    if (isNativePlatform.value || localStorage.getItem('is_native_platform') as string === 'yes') {
      flutterLoginWithGoogle(selectedUserType.value)
      return
    }
    if (isTeacher()) {
      await authService.openSignWithGoogleTeacher()
      return
    }
    if (isStudent() && clientNavigator.isChromeOs()) {
      useLoginStore().setNeedSignIn(true)
      await router.push({name: "redirect-student", replace: true})
      return
    }
    if (isStudent() && !clientNavigator.isChromeOs()) {
      await authService.signWithGooglePopupStudent()
      await routePushOrganizations()
      return
    }
  } finally {
    loading.value = false
  }
}

async function handleEmailAndPasswordLogin() {
  if (!hasSelectedUserType() || !validForm.value) return
  try {
    loading.value = true
    await authService.signInWithEmailAndPassword(email.value, password.value)
    await routePushOrganizations(true)
  } catch (error: any) {
    if (error instanceof FirebaseError) {
      const msgs = []
      if (error?.code === 'auth/user-not-found') {
        msgs.push('Usuário não encontrado')
      }
      if (error?.code === 'auth/wrong-password') {
        msgs.push('Senha incorreta')
      }
      if (error?.code === 'auth/invalid-email') {
        msgs.push('E-mail inválido')
      }
      if (error?.code === 'auth/too-many-requests') {
        msgs.push('Muitas tentativas de login. Tente novamente mais tarde')
      }
      snackBarStore.showSnackbar(msgs, 'error')
    }

    const errorObject = JSON.parse(error?.message)
    if (error instanceof Error && errorObject?.data) {
      const msg = Array.isArray(errorObject?.data) ? errorObject?.data : [errorObject?.data]
      snackBarStore.showSnackbar(msg, 'error')
    }
  } finally {
    loading.value = false
  }
}

async function handleLoginWithCustomToken() {
  const {customToken} = route.query
  if (!customToken) return
  try {
    loading.value = true
    selectedUserType.value = 'teacher'
    await authService.signInWithCustomToken(customToken as string)
    await routePushOrganizations()
  } catch (error) {
    snackBarStore.showSnackbar(['Erro ao autenticar usuário'], 'error')
  } finally {
    loading.value = false
  }
}

async function routePushOrganizations(canGoToFirstAccess = false) {
  if (!hasSelectedUserType()) return
  const redirect = selectedUserType.value
  return await router.push({
    name: 'organizations',
    query: {
      redirect,
      canGoToFirstAccess: Number(canGoToFirstAccess)
    }
  })
}

</script>
