<template>
    <AuthLayoutComponent title="Criar conta" :loading="loading">
        <div class="flex flex-col items-start gap-5 self-stretch w-[356px] xs:w-full">
            <UsernameCardComponent v-if="isRegisterWithGoogle" :userName="formData?.email ?? ''"
                @click-unlink="logoutGoogle" :show-unlink="true">
                <template #icon>
                    <EmailIcon />
                </template>
            </UsernameCardComponent>
            <GoogleButton v-if="!isRegisterWithGoogle" label="Cadastrar com Google" @click="handleRegisterWithGoogle" />
            <DividerWithTextComponent v-if="!isRegisterWithGoogle" text="ou" />
            <v-form v-model="validForm" class="flex flex-col gap-4 self-stretch" @submit="handleRegister"
                @keyup.enter="handleRegister" validate-on="input">
                <v-text-field v-model="formData.name" label="Nome" :rules="rulesName" placeholder="Nome"
                    variant="outlined" :persistent-placeholder="true" autocomplete="off" color="grey">
                </v-text-field>
                <v-text-field v-model="formData.email" label="E-mail ou usuário" :rules="rulesUsername"
                    variant="outlined" :disabled="isRegisterWithGoogle" color="grey">
                </v-text-field>
                <v-text-field
                    v-if="!isRegisterWithGoogle"
                    v-model="formData.password"
                    :rules="rulesPassword"
                    :persistent-placeholder="true"
                    :append-inner-icon="!isPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPassword ? 'text' : 'password'"
                    label="Senha"
                    placeholder="********"
                    variant="outlined"
                    @click:append-inner="isPassword = !isPassword"
                    color="grey"
                ></v-text-field>
                <v-text-field
                    v-if="!isRegisterWithGoogle"
                    v-model="confirmPassword"
                    :rules="[...rulesPassword, matchPassword(formData?.password ?? '')]"
                    :persistent-placeholder="true"
                    :append-inner-icon="!isRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isRepeatPassword ? 'text' : 'password'"
                    label="Senha"
                    placeholder="********"
                    variant="outlined"
                    @click:append-inner="isRepeatPassword = !isRepeatPassword"
                    color="grey"
                ></v-text-field>
                <div class="text-center">
                    <label for="courseUniqueCode" class="font-normal font-rubik text-base">
                        Insira o código da turma fornecido pelo professor.
                    </label>
                    <v-otp-input v-model="formData.courseUniqueCode" type="text" name="courseUniqueCode"
                        id="courseUniqueCode"></v-otp-input>
                </div>
            </v-form>
            <div class="w-full flex flex-col gap-5">
                <RouterLink to="/" class="w-full text-azul-50 text-center font-rubik text-xs underline cursor-pointer">Voltar para o login</RouterLink>
                <PrimaryButton label="Continuar" @click="handleRegister" />
            </div>
        </div>
    </AuthLayoutComponent>
</template>

<script setup lang="ts">
import AuthLayoutComponent from '@/components/AuthLayoutComponent.vue';
import GoogleButton from '@/components/GoogleButton.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import router from '@/router';
import CourseService from '@/services/course';
import { useSnackbarStore } from '@/store/snackbar';
import { rulesName, rulesPassword, rulesUsername, matchPassword } from '@/utils/rules';
import { watch, ref, reactive } from 'vue';
import DividerWithTextComponent from '@/components/DividerWithTextComponent.vue';
import AuthService from '@/services/auth';
import UsernameCardComponent from '@/components/UsernameCardComponent.vue';
import EmailIcon from '@/components/icons/EmailIcon.vue';
import type IRegisterStudentByCodeRequest from '@/interfaces/register-student-by-code.interface';
import QrCodeIcon from "@/components/icons/QrCodeIcon.vue";

const snackbarStore = useSnackbarStore()
const courseService = new CourseService()
const authService = new AuthService()

const loading = ref(false)
const validForm = ref(false)
const isRegisterWithGoogle = ref(false)
const isPassword = ref<boolean>(false)
const isRepeatPassword = ref(false)
const formData = reactive<IRegisterStudentByCodeRequest>({
    name: '',
    email: '',
    password: '',
    username: null,
    courseUniqueCode: ''
})
const confirmPassword = ref<string | null>('')

async function handleRegister() {
    try {
        loading.value = true
        validateFields()
        await courseService.registerOnCourse(formData)
        snackbarStore.showSnackbar(['Usuário cadastrado com sucesso! Já é possível realizar o seu login.'], 'success')
        await router.push('/')
    } catch (e: any) {
        const errorObject = JSON.parse(e?.message)
        if (errorObject?.data) {
            const msg = Array.isArray(errorObject?.data) ? errorObject?.data : [errorObject?.data]
            snackbarStore.showSnackbar(msg, 'error')
        }
    } finally {
        loading.value = false
    }
}

async function handleRegisterWithGoogle() {
    try {
        loading.value = true
        await authService.signWithGooglePopupStudent()
        const user = authService.getCurrentUser()
        if (!user || !user?.email || !user?.displayName) {
            loading.value = false
            return snackbarStore.showSnackbar(['Erro ao cadastrar usuário com Google.'], 'error')
        }
        formData.email = user?.email
        formData.name = user?.displayName
        formData.password = null
        confirmPassword.value = null
        isRegisterWithGoogle.value = true
    } catch {
        clearFields()
    } finally {
        loading.value = false
    }
}

async function logoutGoogle() {
    try {
        loading.value = true
        await authService.signOut()
        isRegisterWithGoogle.value = false
        clearFields()
    } finally {
        loading.value = false
    }
}

function clearFields() {
    formData.name = ''
    formData.email = ''
    formData.password = ''
    formData.courseUniqueCode = ''
    confirmPassword.value = ''
}

watch(formData, (v) => {
    if (!formData?.courseUniqueCode) return;

    formData.courseUniqueCode = v.courseUniqueCode.toUpperCase();
})

function validateFields() {
    const arrayValidations = [
        {
            validation: !validForm.value,
            message: 'Preencha todos os campos corretamente.'
        },
        {
            validation: formData.password !== confirmPassword.value,
            message: 'As senhas não coincidem.'
        },
        {
            validation: !formData.courseUniqueCode,
            message: 'Insira o código da turma fornecido pelo professor.'
        }
    ]

    for (const item of arrayValidations) {
        if (item.validation) {
            throw new Error(item.message)
        }
    }
}
</script>
