import {useUserStore} from "@/store/user.store";
import {db} from "@/config/firebase";
import {
  collection, collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot, orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import AuthService from "./auth";
import type IUpdateUserRequest from "@/interfaces/update-user-request.interface";
import {Post, useFetch} from "@/composables/useFetch";

export default class UserService {

  private call = useFetch();

  public async getCurrentUserId() {
    const authService = new AuthService();
    const email = authService.getCurrentUserEmail();
    if (!email) {
      throw new Error("No user logged in");
    }
    const user = await this.findUserByEmail(email);
    if (!user) {
      throw new Error("No user found");
    }
    return user.id as string;
  }

  private async findUserByEmail(email: string) {
    const q = query(
        collection(db, "users"),
        where("email", "==", email),
        limit(1)
    );
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map((doc) => doc.data());
    return docs[0];
  }

  public async setUserActiveOrganization(organizationId: string) {
    if (!(await this.isOrganizationExists(organizationId))) {
      throw new Error("Organization not found");
    }
    const userRef = doc(db, "users", await this.getCurrentUserId());
    return updateDoc(userRef, {
      active_organization: {
        id: organizationId,
        updated_at: serverTimestamp(),
      },
    });
  }

  public async isOnlyStudent(organizationId: string) {
    const userId = await this.getCurrentUserId()
    const q = query(
        collectionGroup(db, 'people'),
        where('user_id', '==', userId),
        where('level', '>' , '1'),
        where('organization_id', '==', organizationId),
        limit(1)
    )
    const qSnapshot = await getDocs(q)
    return !(qSnapshot?.docs?.length > 0)
  }

  private async isOrganizationExists(organizationId: string) {
    try {
      return (await getDoc(doc(db, "organizations", organizationId))).exists();
    } catch {
      return false;
    }
  }

  public static async listenUserData() {
    const userRef = doc(
        db,
        "users",
        await new UserService().getCurrentUserId()
    );
    const userStore = useUserStore();
    return onSnapshot(userRef, (doc) => {
      userStore.setUserData(doc.data());
    });
  }

  public async updateUser(updateUserRequest: IUpdateUserRequest) {
    return await this.call(
        new Post(),
        "updateUser",
        updateUserRequest,
        true
    );
  }

  public async setUserFirstAccessToFalse() {
    const userRef = doc(db, "users", await this.getCurrentUserId());
    return updateDoc(userRef, {
      is_first_access: false,
    });
  }

  public async getUserPeople(organizationId: string) {
    const userId = await this.getCurrentUserId()
    const collectionRef = collectionGroup(db, "people");
    const q = query(
        collectionRef,
        where("user_id", '==', userId),
        where("organization_id", '==', organizationId),
        orderBy("level", "desc"),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

}
