import AuthService from "@/services/auth";

abstract class MethodCall {
  protected apiUrl = import.meta.env.VITE_BASE_API;
  abstract call(
    endpoint: string,
    body?: any,
    isAuthenticated?: boolean,
    meilisearchAuthToken?: string,
    credentials?: RequestCredentials
  ): Promise<any>;
}

export class Get extends MethodCall {
  async call(
    endpoint: string,
    params?: any,
    isAuthenticated = false,
    meilisearchAuthToken = "",
    credentials: RequestCredentials = "include"
  ) {
    const response = await fetch(
      new URL(
        endpoint + (params ? "?" + new URLSearchParams(params) : ""),
        this.apiUrl
      ),
      {
        method: "GET",
        credentials,
        headers: {
          "Content-Type": "application/json",
          ...(isAuthenticated && {
            Authorization: "Bearer " + (await new AuthService().getIdToken()),
          }),
          ...(meilisearchAuthToken && {
            Authorization: "Bearer " + meilisearchAuthToken,
          }),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return await response.json();
  }
}

export class Post extends MethodCall {
  async call(endpoint: string, body: any, isAuthenticated = false) {
    const response = await fetch(new URL(endpoint, this.apiUrl), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...(isAuthenticated && {
          Authorization: "Bearer " + (await new AuthService().getIdToken()),
        }),
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return await response.json();
  }
}

export const useFetch = () => {
  return async (
    method: MethodCall,
    endpoint: string,
    body?: any,
    isAuthenticated?: boolean,
    meilisearchAuthToken?: string,
    credentials?: RequestCredentials
  ) =>
    await method.call(
      endpoint,
      body,
      isAuthenticated,
      meilisearchAuthToken,
      credentials
    );
};
