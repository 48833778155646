<template>
    <button class="__button_app_store" @click="openStore">
        <component :is="props.plataform === OperationSystemEnum.iOS ? AppStoreIcon : GooglePlayIcon" />
        <div class="__text_container">
            <span class="__text_avaliable">{{ avaliableName }}</span>
            <span class="__text_app_store_name">{{ appStoreName }}</span>
        </div>
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AppStoreIcon from './icons/AppStoreIcon.vue';
import GooglePlayIcon from './icons/GooglePlayIcon.vue';
import Environment from '@/config/env';
import OperationSystemEnum from '@/enum/operation-system.enum';

const props = defineProps({
    plataform: {
        type: String as () => OperationSystemEnum,
        required: true
    }
})

const appStoreName = computed(() => {
    return props.plataform === OperationSystemEnum.iOS ? "App Store" : "Google Play";
})

const avaliableName = computed(() => {
    return props.plataform === OperationSystemEnum.iOS ? "Disponível na" : "Disponível no";
})

const openStore = () => {
    if (props.plataform === OperationSystemEnum.iOS) {
        return window.open(Environment.STORE_IOS_URL, '_blank');
    }

    if (props.plataform === OperationSystemEnum.Android) {
        return window.open(Environment.STORE_ANDROID_URL, '_blank');
    }
}
</script>

<style scoped>
.__button_app_store {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 16px;
    border-radius: 8px;
    background-color: #000;
}
.__text_container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 8px;
}
.__text_avaliable {
    color: var(--Stroke, #FFF);
    font-family: Rubik;
    font-size: 7.271px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.186px; 
    letter-spacing: 0.14px;
}
.__text_app_store_name {
    color: var(--Stroke, #FFF);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.186px; 
    letter-spacing: 0.14px;
}
</style>