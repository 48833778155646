<template>
    <v-layout class="flex p-10 justify-center items-start xs:p-5">
        <v-main class="flex justify-center lg:flex-col lg:items-center lg:gap-5">
            <KidsImage />
            <div class="bg-white shadow-default rounded-br-[12px] rounded-tr-[12px] kids-image:rounded-[12px] xs:w-full">
                <v-progress-linear v-if="loading" indeterminate color="#1C86E3" />
                <v-overlay :model-value="loading" :persistent="true" />
                <div id="title" class="flex px-5 py-4 items-center gap-5 border-b-[1px]">
                    <span class="flex-[1_0_0] text-preto-cone text-xl font-normal font-rubik">
                        {{ title }}
                    </span>
                    <img
                        class="hidden kids-image:block"
                        :src="iconUrlComputed"
                        width="90"
                        height="30"
                        alt="Imagem de logo da empresa"
                        fetchpriority="low"
                        rel="preload">
                </div>
                <div id="content" class="flex py-5 px-6 items-start gap-5 w-full">
                    <slot />
                </div>
            </div>
            <DownloadApp />
        </v-main>
    </v-layout>
</template>

<script setup lang="ts">
import DownloadApp from './DownloadApp.vue';
import KidsImage from "@/components/KidsImage.vue";
import Environment from "@/config/env";
import {computed} from "vue";

defineProps({
    title: {
        type: String,
        default: 'Login'
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const icon_url = Environment.LOGO_URL;

const iconUrlComputed = computed(() => {
    if (icon_url.includes('pruvo_icon.png')) {
        return '/assets/pruvo_icon_blue.png';
    }
    return icon_url;
})


</script>
