<script setup lang="ts">
import {RouterView } from 'vue-router'
import SnackbarComponent from './components/SnackbarComponent.vue';
import AuthService from './services/auth';

AuthService.listenUserData()

</script>

<template>
  <SnackbarComponent />
  <RouterView />
</template>
