<template>
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.89577 1.21946C0.647554 1.47314 0.503906 1.86811 0.503906 2.37961V20.6245C0.503906 21.136 0.647554 21.531 0.89577 21.7846L0.957032 21.8403L11.4286 11.6206V11.3793L0.957032 1.15964L0.89577 1.21946Z" fill="url(#paint0_linear_1_2)"/>
        <path d="M14.9163 15.0288L11.4297 11.6205V11.3792L14.9206 7.97093L14.9987 8.01528L19.1328 10.3119C20.3127 10.9636 20.3127 12.0361 19.1328 12.692L14.9987 14.9845L14.9163 15.0288Z" fill="url(#paint1_linear_1_2)"/>
        <path d="M14.9964 14.9848L11.4273 11.5002L0.894531 21.7848C1.2864 22.187 1.92542 22.2355 2.65211 21.8333L14.9964 14.9848Z" fill="url(#paint2_linear_1_2)"/>
        <path d="M14.9964 8.01541L2.65211 1.16688C1.92542 0.768815 1.2864 0.817283 0.894531 1.21947L11.4273 11.5L14.9964 8.01541Z" fill="url(#paint3_linear_1_2)"/>
        <defs>
        <linearGradient id="paint0_linear_1_2" x1="10.4988" y1="20.8145" x2="-3.34268" y2="6.63758" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00A0FF"/>
        <stop offset="0.0066" stop-color="#00A1FF"/>
        <stop offset="0.2601" stop-color="#00BEFF"/>
        <stop offset="0.5122" stop-color="#00D2FF"/>
        <stop offset="0.7604" stop-color="#00DFFF"/>
        <stop offset="1" stop-color="#00E3FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1_2" x1="20.6692" y1="11.4987" x2="0.223" y2="11.4987" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFE000"/>
        <stop offset="0.4087" stop-color="#FFBD00"/>
        <stop offset="0.7754" stop-color="#FFA500"/>
        <stop offset="1" stop-color="#FF9C00"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1_2" x1="13.0556" y1="9.60591" x2="-5.71458" y2="-9.61913" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF3A44"/>
        <stop offset="1" stop-color="#C31162"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1_2" x1="-1.7568" y1="27.8546" x2="6.62495" y2="19.2699" gradientUnits="userSpaceOnUse">
        <stop stop-color="#32A071"/>
        <stop offset="0.0685" stop-color="#2DA771"/>
        <stop offset="0.4762" stop-color="#15CF74"/>
        <stop offset="0.8009" stop-color="#06E775"/>
        <stop offset="1" stop-color="#00F076"/>
        </linearGradient>
        </defs>
    </svg>        
</template>