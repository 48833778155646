enum BrowserEnum {
    Chrome = "Chrome",
    Firefox = "Firefox",
    Safari = "Safari",
    Edge = "Edge",
    IE = "IE",
    Opera = "Opera",
    Unknown = "Unknown",
}

export default BrowserEnum;