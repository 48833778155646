import { defineStore } from "pinia";

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
      snackbar: {
        show: false,
        messages: [] as Array<string>,
        color: 'success'
      }
    }),
    actions: {
      hideSnackbar() {
        this.snackbar.show = false
        this.snackbar.messages = []
      },
      showSnackbar(messages: Array<string>, color: string, time: number = 5000) {
        this.snackbar.show = true
        this.snackbar.messages = messages
        this.snackbar.color = color
        setTimeout(() => {
          this.hideSnackbar()
        }, time)
      }
    },
    getters: {
      getColor() {
        switch (this.snackbar.color) {
          case 'success':
            return 'green'
          case 'error':
            return 'red'
          case 'warning':
            return 'yellow'
          case 'info':
            return 'blue'
          default:
            return 'green'
        }
      }
    }
  })