import { defineStore } from 'pinia';

export const useLoginStore = defineStore('login', {
    state: () => ({
        isNeedSignIn: false,
    }),
    actions: {
        setNeedSignIn(value: boolean) {
            this.isNeedSignIn = value;
        },
    },
    getters: {
        needSignIn(): boolean {
            return this.isNeedSignIn;
        },
    },
});