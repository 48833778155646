enum OperationSystemEnum {
    Windows = "Windows",
    MacOS = "Mac",
    Linux = "Linux",
    Android = "Android",
    iOS = "iOS",
    ChromeOS = "Chrome OS",
    Unknown = "Unknown"
}

export default OperationSystemEnum;