import { Post, useFetch } from "@/composables/useFetch";
import Environment from "@/config/env";
import type RegisterStudentByCodeRequest from "@/interfaces/register-student-by-code.interface";

export default class CourseService {
  private call = useFetch();
  private emailRegex = Environment.EMAIL_REGEX;

  public async registerOnCourse(data: RegisterStudentByCodeRequest) {
    if (data?.email && !this.emailRegex.test(data?.email)) {
      data.username = data.email;
      data.email = null;
    }
    return await this.call(new Post(), "registerStudentByCode", data);
  }
}
