import { database } from "@/config/firebase"
import { onValue, type DatabaseReference, type Unsubscribe, ref, Database, set } from "firebase/database"
import { v4 as uuidv4 } from "uuid"

enum QrCodeStatus {
    PENDING = "pending",
    SCANNED = "scanned",
    EXPIRED = "expired",
    USED = "used",
    INVALID = "invalid"
}

class QrCodeModel {
    public id: string
    public createdAt: number
    public updatedAt: number
    public userId: string | null
    public scanStatus: QrCodeStatus | null
    public customToken: string | null
    public email: string | null

    constructor(
        id: string,
        createdAt: number,
        updatedAt: number,
        userId: string | null,
        scanStatus: QrCodeStatus | null,
        customToken: string | null,
        email: string | null
    ) {
        this.id = id
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.userId = userId
        this.scanStatus = scanStatus
        this.customToken = customToken
        this.email = email
    }
}

export default class QrCodeService {
    private databaseRef!: DatabaseReference
    private unsubscribe!: Unsubscribe
    private database: Database

    constructor() {
        this.database = database
    }

    public async generateQrCode(): Promise<string> {
        const qrCode = this.getQrCode()
        this.databaseRef = ref(this.database, `qrCodeLogin/${qrCode.id}`)
        await set(this.databaseRef, qrCode)
        return this.encryptQrCode(qrCode);
    }

    private getQrCode(): QrCodeModel {
        return new QrCodeModel(
            uuidv4(),
            Date.now(),
            Date.now(),
            null,
            QrCodeStatus.PENDING,
            null,
            null
        )
    }

    public subscribeToQrCode(callback: (qrCode: QrCodeModel) => void) {
        if (this.unsubscribe) {
            this.unsubscribe()
        }
        this.unsubscribe = onValue(this.databaseRef, (snapshot) => {
            callback(snapshot.val() as QrCodeModel)
        })
    }

    public unsubscribeToQrCode() {
        this.unsubscribe()
    }

    private encryptQrCode(qrCode: QrCodeModel): string {
        return btoa(JSON.stringify(qrCode))
    }

}