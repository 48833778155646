<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3423_105845)">
        <path d="M9.99968 0.833984C8.18668 0.833984 6.4144 1.3716 4.90695 2.37885C3.3995 3.38609 2.22459 4.81773 1.53078 6.49272C0.83698 8.16771 0.655449 10.0108 1.00915 11.789C1.36284 13.5671 2.23589 15.2005 3.51787 16.4825C4.79985 17.7644 6.43319 18.6375 8.21135 18.9912C9.98951 19.3449 11.8326 19.1633 13.5076 18.4695C15.1826 17.7757 16.6142 16.6008 17.6215 15.0934C18.6287 13.5859 19.1663 11.8136 19.1663 10.0007C19.1635 7.57038 18.1968 5.24047 16.4783 3.52201C14.7599 1.80354 12.43 0.836851 9.99968 0.833984ZM9.99968 17.5007C8.51632 17.5007 7.06627 17.0608 5.8329 16.2367C4.59953 15.4126 3.63824 14.2412 3.07058 12.8708C2.50293 11.5003 2.3544 9.99233 2.64379 8.53747C2.93318 7.08262 3.64748 5.74624 4.69638 4.69735C5.74527 3.64846 7.08164 2.93415 8.5365 2.64476C9.99136 2.35537 11.4994 2.5039 12.8698 3.07155C14.2403 3.63921 15.4116 4.60051 16.2357 5.83387C17.0598 7.06724 17.4997 8.51729 17.4997 10.0007C17.4975 11.9891 16.7066 13.8955 15.3005 15.3015C13.8945 16.7076 11.9881 17.4984 9.99968 17.5007ZM14.9997 10.0007C14.9997 10.2217 14.9119 10.4336 14.7556 10.5899C14.5993 10.7462 14.3874 10.834 14.1663 10.834H9.99968C9.77867 10.834 9.5667 10.7462 9.41042 10.5899C9.25414 10.4336 9.16635 10.2217 9.16635 10.0007L9.16635 5.00065C9.16635 4.77964 9.25414 4.56768 9.41042 4.4114C9.5667 4.25512 9.77867 4.16732 9.99968 4.16732C10.2207 4.16732 10.4327 4.25512 10.5889 4.4114C10.7452 4.56768 10.833 4.77964 10.833 5.00065L10.833 9.16732H14.1663C14.3874 9.16732 14.5993 9.25512 14.7556 9.4114C14.9119 9.56768 14.9997 9.77964 14.9997 10.0007Z" fill="#4A545E"/>
        </g>
        <defs>
        <clipPath id="clip0_3423_105845">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>