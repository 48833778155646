import { onMounted, onUnmounted, ref } from "vue"

export function useFlutter() {
    const isNativePlatform = ref(false)
    const isAndroid = ref(false)
    const isIos = ref(false)

    onMounted(() => {
        isNativePlatform.value = localStorage.getItem('is_native_platform') as string === 'yes'
        isAndroid.value = localStorage.getItem('os_platform') === 'android'
        isIos.value = localStorage.getItem('os_platform') === 'ios'
        window.addEventListener('storage', onStorageEvent)
    })
    onUnmounted(() => window.removeEventListener('storage', onStorageEvent))

    function onStorageEvent(e: StorageEvent) {
        if (e.key === 'is_native_platform') {
            isNativePlatform.value = e.newValue === 'yes'
        }
        if (e.key === 'os_platform') {
            isAndroid.value = e.newValue === 'android'
            isIos.value = e.newValue === 'ios'
        }
    }

    function postMessage(message: any) {
        try {
            // @ts-ignore
            window.FlutterChannel.postMessage(JSON.stringify(message))
        } catch {
            // Do nothing
        }
    }

    function openLoginWithGoogle(userType: string) {
        postMessage({
            type: "loginWithGoogle",
            userType
        })
    }

    function loginWithQrCode(email: string) {
        postMessage({
            type: "loginWithQrCode",
            email
        })
    }

    function launchUrl(url: string) {
        postMessage({
            type: "launchUrl",
            url
        })
    }

    return {
        isNativePlatform,
        isAndroid,
        isIos,
        openLoginWithGoogle,
        launchUrl,
        loginWithQrCode
    }
}
