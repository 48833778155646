<template>
    <div v-show="isAndroidOrIos && !isNativePlatform && mobile"
        class="hidden sm:flex pa-4 flex-col items-center gap-5 bg-white rounded-xl shadow-default w-full" >
        <span class="text-preto-cone font-rubik text-center font-xl font-normal">Baixe o app</span>
        <div class="flex justify-center items-start gap-5 self-stretch">
            <AppStoreButton v-if="clientNavigator.isIos()" :plataform="OperationSystemEnum.iOS" />
            <AppStoreButton v-if="clientNavigator.isAndroid()" :plataform="OperationSystemEnum.Android"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useNavigator } from '@/composables/useNavigator';
import AppStoreButton from './AppStoreButton.vue';
import { computed } from 'vue';
import { useFlutter } from '@/composables/useFlutter';
import { useDisplay } from 'vuetify';
import OperationSystemEnum from '@/enum/operation-system.enum';

const clientNavigator = useNavigator()
const flutter = useFlutter();
const { mobile } = useDisplay()

const isAndroidOrIos = computed(() => {
    return clientNavigator.isIos() || clientNavigator.isAndroid()
})

const isNativePlatform = computed(() => {
    return flutter?.isNativePlatform?.value
})

</script>
