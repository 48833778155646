import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Environment from './config/env'

const vuetify = createVuetify({
  components,
  directives,
})

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: Environment.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /pruvo\.app/gm],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
})

app.use(router)
app.use(pinia)
app.use(vuetify)

app.mount('#app')
